import React from 'react';
import VideoClip from './VideoClip';
import uniqid from 'uniqid';

const VideoList = ({ videoClips }: any) => {
    return (
        <div className="video-list">
            {videoClips.map((clip: any) => (
                <VideoClip key={uniqid()} clip={clip} />
            ))}
        </div>
    );
};

export default VideoList;
