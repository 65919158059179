import React from 'react';
import NavBarBottom from '../../component/navigation/NavBarBottom';
import logo from '../../logo_ ADT.svg';
import './_expired.scss';
import { Link } from 'react-router-dom';

function ExpiredPage() {
    return (
        <>
            <div className="expire-block text-center">
                <div className="custom-width">
                    <img className="site-logo mb-4" src={logo} alt="ADT Logo" />
                    <p className="expire-content">This page has expired.</p>
                    <p className="expire-content">
                        Please visit{' '}
                        <Link to="https://www.myadt.com/" target="_blank">
                            MyADT
                        </Link>{' '}
                        to view the event history.
                    </p>
                </div>
            </div>
            <NavBarBottom />
        </>
    );
}

export default ExpiredPage;
