import React, { useState } from 'react';
import './_video.scss';
import ModalVideo from 'react-modal-video';
import uniqid from 'uniqid';

const VideoClip = ({ clip }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');

    const openVideoModal = (videoUrl: any) => {
        setSelectedVideo(videoUrl);
        setIsOpen(true);
    };

    const closeVideoModal = () => {
        setSelectedVideo('');
        setIsOpen(false);
    };

    const getThumbnailUrl = () => {
        if (clip.thumbnail_url) {
            return clip.thumbnail_url;
        }
        return `${window.location.origin}/temp_default_thumb.jpg`;
    };

    return (
        <div className="video-wrapper clip-area" key={uniqid()}>
            <div
                key={clip.ivnid}
                className="thumbnail"
                onClick={() => openVideoModal(clip.media_url)}
                onKeyDown={() => openVideoModal(clip.media_url)}
            >
                <p>{clip.name}</p>
                <div className="thumbnail-overlay">
                    <img src={getThumbnailUrl()} alt={clip.name} />
                    <div
                        className="play-button"
                        data-testid="play-button"
                        onClick={() => openVideoModal(clip.media_url)}
                        onKeyDown={() => openVideoModal(clip.media_url)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                        >
                            <polygon points="22,14 22,50 50,32" />
                        </svg>
                    </div>
                </div>
            </div>

            <ModalVideo
                data-testid="modal-video"
                channel="custom"
                isOpen={isOpen}
                url={selectedVideo}
                onClose={closeVideoModal}
            />
        </div>
    );
};

export default VideoClip;
