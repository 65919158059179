import React from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import ExpiredPage from './pages/expirePage/ExpiredPage';
import { MessageProvider } from './data/MessageContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = React.lazy(() => import('./pages/main/Main'));
const NoMatch = React.lazy(() => import('./pages/nopage/NoPage'));

function App() {
    return (
        <MessageProvider>
            <div className="App">
                <div className="max-w-786 main-block">
                    <Routes>
                        <Route
                            path="/:tokenId"
                            element={
                                <React.Suspense>
                                    <Main />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/expired"
                            element={
                                <React.Suspense>
                                    <ExpiredPage />
                                </React.Suspense>
                            }
                        />
                        <Route path="*" element={<NoMatch />} />
                    </Routes>
                </div>
            </div>
            <ToastContainer />
        </MessageProvider>
    );
}

export default App;
