import React from 'react';
import { RiShieldStarFill } from 'react-icons/ri';
import uniqid from 'uniqid';
import adt_Logo from '../../logo_ ADT.svg';
import { timeFormatter } from '../../utils/helper';

const RequestPoliceMsgBox = (props: any) => {
    return (
        <div
            className="d-flex align-items-center position-relative outside-message mb-2 mt-2"
            key={uniqid()}
        >
            <div className="user-avatar-logo">
                <img src={adt_Logo} alt="adt-logo" />
            </div>
            <div className="position-relative">
                <div className="request-police-confirm alarm-info">
                    <div className="d-flex">
                        <div className="alarm-icon">
                            <RiShieldStarFill />
                        </div>
                        <p className="view-message-text">
                            Police requested by <b>{props.publisherName}</b> at{' '}
                            {timeFormatter(props.msgTime)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestPoliceMsgBox;
